import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import '../global.css'
import favicon from '../favicon.png'
import Nav from '../navigation.js'
import Footer from '../footer.js'

import contactBg from '../contactBg.jpg'
import location1 from '../mapLocation1.png'
import location2 from '../mapLocation2.png'
import location3 from '../mapLocation3.png'

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  .sectionHeader {
    font-size: 1.4em;
    padding: 45px 0;
  }
  .mail {
    text-align: center;
    padding-top: 80px;
    @media (max-width: 800px) {
      padding: 40px 0 70px;
    }
    h1 {
      font-size: 1.3em;
      padding: 20px 0;
    }
    .address {
      display: inline-block;
      width: 70%;
      color: rgba(255, 255, 255, 0.3);
      font-family: nunitoReg, sans-serif;
      line-height: 1.5em;
      a {
        color: white;
      }
    }
  }
`

const Hero = styled.header`
  text-align: center;
  position: relative;
  height: 50%;
  background-image: url(${contactBg});
  background-size: cover;
  background-position: center;
  .wrapper {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    h1 {
      font-size: 3em;
      @media (max-width: 800px) {
        font-size: 2.4em;
      }
    }
    span {
      font-family: nunitoReg, sans-serif;
      width: 90%;
      display: inline-block;
      margin-top: 10px;
      font-size: 1.2em;
      @media (max-width: 800px) {
        font-size: 1.1em;
        width: 80%;
      }
    }
  }
`

const Offices = styled.div`
  text-align: center;
  @media (max-width: 800px) {
    padding: 0;
  }
  .locations {
    display: flex;
    justify-content: center;
    @media (max-width: 800px) {
      flex-wrap: wrap;
    }
    a {
      color: white;
    }
    img {
      width: 50%;
    }
    div {
      flex: 0 33%;
      @media (max-width: 800px) {
        flex: 0 100%;
        margin: 30px 0;
      }
    }
    span {
      display: inline-block;
    }
    span {
      font-size: 1.3em;
      width: 100%;
      padding: 8px 0;
      &:last-child {
        font-size: 1em;
        width: 150px;
        opacity: 0.5;
        font-family: nunitoReg, sans-serif;
      }
    }
  }
`

export default () => (
  <Container>
    <Helmet>
      <title>Splendo</title>
      <meta charSet="utf-8" />
      <meta name="description" content="Splendo" />
      <link rel="icon" type="image/png" href={favicon} sizes="50x50" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    </Helmet>

    <Nav />

    <Hero>
      <div className="wrapper">
        <h1>Contact Us</h1>
        <span>Feel free to get in touch with us anytime!</span>
      </div>
    </Hero>

    <Offices>
      <h1 className="sectionHeader">Our Offices</h1>

      <div className="locations">
        <div>
          <a
            href="https://www.google.com/maps/place/Marineweg+5a,+2241+TX+Wassenaar,+Netherlands/@52.1496573,4.4254044,16.12z/data=!4m5!3m4!1s0x47c5c79de27b4ef9:0x1d3731a4f5ad03e6!8m2!3d52.1498487!4d4.4296483"
            target="_blank"
            without
            rel="noopener noreferrer"
          >
            <img src={location1} alt="Splendo HQ" />
            <span>Splendo HQ</span>
            <span className="address">
              Marineweg 5A <br />
              2241 TX Wassenaar <br />
              The Netherlands
            </span>
          </a>
        </div>

      </div>
    </Offices>

    <div className="mail">
      <svg width="50" height="50" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M80 30.7131C80 29.9895 79.5325 29.3888 78.8879 29.1585L69.8958 22.3174V9.42744C69.8958 8.50704 69.1496 7.76078 68.2292 7.76078H50.7625L41.0091 0.340574C40.4134 -0.113525 39.5866 -0.113525 38.9909 0.340574L29.2362 7.76078H11.7708C10.8504 7.76078 10.1042 8.50704 10.1042 9.42744V22.3164L1.11094 29.1587C0.466919 29.3893 0 29.9899 0 30.7131V75.21C0 77.8516 2.26562 80 5.04964 80H74.9495C77.7344 80 80 77.8516 80 75.21V30.7131ZM69.8958 26.5057L75.4264 30.7131L69.8958 34.9208V26.5057ZM40 3.76097L45.2573 7.76078H34.742L40 3.76097ZM13.4375 11.0941H50.1789C50.1875 11.0942 50.1957 11.0982 50.2043 11.0982C50.2104 11.0982 50.216 11.0942 50.2221 11.0941H66.5625V37.4568L40 57.6653L13.4375 37.4572V11.0941ZM10.1042 26.5048V34.9213L4.57275 30.7131L10.1042 26.5048ZM3.33333 75.0877V33.9583L30.3645 54.5229L3.33333 75.0877ZM6.76331 76.6667L33.1173 56.6171L38.9909 61.0857C39.2887 61.3128 39.6444 61.4259 40 61.4259C40.3556 61.4259 40.7113 61.3128 41.0091 61.0857L46.8823 56.6174L73.2367 76.6667H6.76331ZM76.6667 75.0877L49.635 54.5231L76.6667 33.9578V75.0877Z"
          fill="white"
        />
      </svg>

      <h1>Mailing Address</h1>

      <span className="address">
        P.O. Box 2185 2240 CD Wassenaar The Netherlands <br /> Phone: +31 (0)70 5121890 <br /> E-mail:
        <a href="mailto:info@splendo.com"> info@splendo.com</a>
      </span>
    </div>

    <Footer />
  </Container>
)
